<template>
    <div class="top-lock-area order-lock-area">    
        <div class="order-header">  
            <user-info></user-info>
            <top-order-details></top-order-details>
            <order-actions></order-actions>
        </div>
        <div class="search-header">
            <div class="search-container">
                <button type="button" class="refresh-search" @click="refreshSearch"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                <div class="search-input">
                    <input type="text" id="search-listing" placeholder="חיפוש הזמנה" class="search-listing" v-model="orderSearch.search.open_search" @change="changeInSearch()"/>
                </div>
            </div>
            <div class="search-radio">
                <strong>הצג הזמנות</strong>
                <div class="form-group">
                    <input type="radio" name="seach-param"  id="ביטול" value="1" v-model="orderSearch.search.for_prepation"  @change="changeInSearch()"/>
                    <label for="ביטול">להכנה</label>
                </div>
                <div class="form-group">
                    <input type="radio" name="seach-param"  id="לליקוט"  value="0" v-model="orderSearch.search.for_prepation"  @change="changeInSearch()">
                    <label for="לליקוט">מבוטלות</label>
                </div>
            </div>
        </div>
        <div class="search-bar-lists">
            <div class="search-bar-first-btn">
                <button type="button" @click="resetAllSearch()">בטל סינונים</button>
            </div>
            <form class="search-bar-form" action="/action_page.php">
                <div class="form-group">
                    <div class="field-wrap">
                        <span class="selected-value" v-if="orderSearch.searchLabel.orderType">{{orderSearch.searchLabel.orderType.text}}</span>  
                        <a href="#" title="Close" class="delete-form-fields" @click="resetSearchValue('orderType',0)" v-if="orderSearch.searchLabel.orderType"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                    <select class="form-control" v-model="orderSearch.searchField.orderType" @change="updateSearch('orderType')">
                        <option v-for="option in orderSearch.orderTypes" v-bind:value="option" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="field-wrap">
                        <span class="selected-value" v-if="orderSearch.searchLabel.rider">{{orderSearch.searchLabel.rider.text}}</span>  
                        <a href="#" title="Close" class="delete-form-fields" @click="resetSearchValue('rider',0)" v-if="orderSearch.searchLabel.rider"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                    <select class="form-control" v-model="orderSearch.searchField.rider" @change="updateSearch('rider')">
                        <option v-for="option in orderSearch.riders" v-bind:value="option" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="field-wrap">
                        <span class="selected-value" v-if="orderSearch.searchLabel.city">{{orderSearch.searchLabel.city.text}}</span>  
                        <a href="#" title="Close" class="delete-form-fields"  @click="resetSearchValue('city',0)" v-if="orderSearch.searchLabel.city"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                    <select class="form-control" v-model="orderSearch.searchField.city" @change="updateSearch('city')">
                        <option v-for="option in orderSearch.cities" v-bind:value="option" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <datepicker :tilldate="from_date" field="from_date" placeholder="מתאריך אספקה" divclass="from-date" @emitDate="emitDate" :key="fromDateKey"></datepicker>
                <datepicker :tilldate="tilldate" field="till_date" placeholder="עד תאריך אספקה" divclass="to_date" @emitDate="emitDate" :key="tillDateKey"></datepicker>
                <div class="form-group last-dropdown"> 
                    <div class="field-wrap">
                        <span class="selected-value" v-if="orderSearch.searchLabel.sort">{{orderSearch.searchLabel.sort.text}}</span>  
                        <a href="#" title="Close" class="delete-form-fields" @click="resetSearchValue('sort',0)" v-if="orderSearch.searchLabel.sort"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                    <select class="form-control"  v-model="orderSearch.searchField.sort" @change="updateSearch('sort')">
                        <option v-for="option in orderSearch.sorts" v-bind:value="option" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UserInfo from './toparea/UserInfo'
import OrderActions from './toparea/OrderActions'
import TopOrderDetails from './toparea/TopOrderDetails'
import datepicker from '../../components/datepicker'

export default {
    components:{UserInfo,OrderActions,TopOrderDetails,datepicker},
    data () {
        return {
            tillDateKey: 0,
            fromDateKey: 0,
        }
    },
    computed: {
        ...mapState({ 
            user: state => state.user.userToken,
            requestStatus: state => state.user.status,
            orderSearch: state => state.ordersearch,
        }),
        ...mapActions('ordersearch', ['getSearchAttr','searchData','searchOrders','doEmpty','updateSearchData','resetValue','resetAllValue']),
        tilldate: function () {
            return this.orderSearch.search.till_date;
        },
        from_date: function () {
            return this.orderSearch.search.from_date;
        }
    }, 
    mounted(){
        this.$store.dispatch('ordersearch/getSearchAttr');
    },
    methods: {
        changeInSearch(){
            var newData = this.orderSearch.search;
            this.$store.dispatch('ordersearch/searchData',newData);
        },
        updateSearch(field){
            var data = this.orderSearch.searchField;
            this.$store.dispatch('ordersearch/updateSearchData',{data,field});
        },
        onClickEmpty(variable_name,value){
            this.$store.dispatch('ordersearch/doEmpty',{variable_name,value});
        },
        resetSearchValue(variable_name,value){
            this.$store.dispatch('ordersearch/resetValue',{variable_name,value});
        },
        emitDate({value,field,key}){
            var variable_name = field;
           this.$store.dispatch('ordersearch/doEmpty',{variable_name,value});
            if(key > 0){
                this.tillDateKey = this.tillDateKey+1;
            }
        },
        resetAllSearch(){
            this.$store.dispatch('ordersearch/resetAllValue');
            // setTimeout(function(){
            this.tillDateKey = this.tillDateKey + 1;
            this.fromDateKey = this.fromDateKey + 1;
            // },500)
        },
        refreshSearch(){
            this.$store.dispatch('ordersearch/searchOrders');
        }
    }
};
</script>
