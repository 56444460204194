<template>
    <div class="form-group" :class="divclass">
        <div class="field-wrap">
            <span class="selected-value">{{appearingDate}}</span>  
        </div>
        <datepicker v-model="newdate" :inputFormat="inputFormat" :locale="locale"></datepicker>
    </div>    
</template>

<script> 
import Datepicker from 'vue3-datepicker'
import { he } from 'date-fns/locale'

export default {
    components:{Datepicker},
    props:['tilldate','field','divclass','placeholder'],
    emits: ['emitDate'],
    data () {
        return {
            newdate: null,//new Date(Date.now() + 12096e5),
            appearingDate: null,//new Date(Date.now() + 12096e5),
            inputFormat: 'dd-MM-yyyy',
            valid_date: '03-10-2021',
        }
    },
    mounted(){
        this.setDate(this.tilldate);

    },
    computed: {
        locale: () => he,
    },
    methods:{
        setDate(dateAttr){
            if(dateAttr == null){
                this.newdate = new Date();
                this.appearingDate = this.formatDate(this.newdate);
            }else{
                this.newdate = this.formatDateforView(dateAttr);
                this.appearingDate = dateAttr;
            }
        },
        formatDate(date) {
            var d = new Date(date),
            month = '' + (d.getMonth()+1),
            day = '' + d.getDate(),
            year = d.getFullYear();

            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [day, month, year].join('-');
        },
        formatDateforView(dateAttr) {
            if(dateAttr != '' && dateAttr != undefined && dateAttr != null){
                var d = new Date();
                var splitDate = dateAttr.split('-');
                d.setFullYear(splitDate[2], parseInt(splitDate[1])-1, parseInt(splitDate[0]));
                return d;
            }
        }
    },
    watch: {
      newdate: function (newVal) {
        var field = this.field;
        const value = this.formatDate(newVal);
        this.appearingDate = value;
        var key = 0;
        this.$emit('emitDate',{field,value,key});
        // if(field == 'from_date'){
        //     field = 'till_date';
        //     key = 1;
        //     this.$emit('emitDate',{field,value,key});
        // }
      }
    },
};
</script>
