<template>
    <div class="main-content-wrap">
        <top-area></top-area>
        <div class="order-listing-wrap">
            <div class="order-listings">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th><input type="checkbox" id="master-checkbox" @change="select_all" value="true" v-model="masterCheckbox"></th>
                            <th scope="col" v-for="(tab_col,index) in tab_cols" :key="index">{{tab_col}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row,index) in orderSearch.orders" :key="index" class="order-data-row">
                            <td><input type="checkbox" :value="row.order_id" v-model="orderCheckboxes" @change="orderCheckbox()" class="lists-check" name="order-1"/></td>
                            <td class="order-status">{{row.order_number}}<br><span class="phone-trans" :style="{'background-color':row.color}">{{row.status_name}}</span></td>
                            <td>{{row.order_type}}</td>
                            <td><p class="order-date-details"><span v-html="row.delivery_date_special"></span><i>{{row.time}}</i></p></td>
                            <td>{{row.state_name}}</td>
                            <td><p class="order-address-details">{{row.full_address}}
                                    <span class="d-block" v-if="row.comment">{{row.comment}}</span></p></td>
                            <td>{{row.full_name}}</td>
                            <td>
                                <p class="orders-phone">
                                    <a :href="'tel:'+row.phone_number">{{row.phone_number}}</a>
                                    <a v-if="row.wa_phone_number" :href="'https://api.whatsapp.com/send?phone='+row.wa_phone_number" class="whatsapp_icon_phone" target="_blank">
                                        <img src="https://market50.b-cdn.net/whatsapp_icon.png" alt="whatapp"/>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
</template>

<script>
import TopArea from './TopArea'
import { mapState, mapActions } from 'vuex'

export default {
    components:{TopArea},
    computed: {
        ...mapState({ 
            orderSearch: state => state.ordersearch,
        }),
    }, 
     data () {
        return {
            tab_cols: ['סטאטוס', 'סוג' , 'ת.מסירה' , 'יישוב   ' , 'כתובת' , 'שם הלקוח' , 'טלפון' ],
            orderCheckboxes:[],
            masterCheckbox: false,
        }
    },
    methods: {
        ...mapActions('ordersearch', ['orderCheckbox']),
        orderCheckbox(){
            let value = this.orderCheckboxes;
           this.$store.dispatch('ordersearch/orderCheckbox',{value});
        },
        select_all(){
            let arr2 = [];
            if(this.masterCheckbox){
                this.orderSearch.orders.forEach(function (value) {
                    arr2.push(value.order_id);
                });
            }
            this.orderCheckboxes = arr2;
            this.orderCheckbox();
        },
    },
};
</script>