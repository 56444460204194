<template>
    <div class="order-actions">
        <button type="button" class="charge-order">
            <img src="img/credit-card.svg" class="img-fluid" alt="charge" />חייב מסומנים</button>
        <button @click="printStickers('stickers')" type="button" class="print-sticker btn-site" data-toggle="modal" data-target="#print-label-modal"><img src="img/printer-orange.svg" hover-img="img/printer-black.svg" class="img-fluid" alt="printer" />הדפס מדבקות</button>
        <button @click="printStickers('orderpdf')" type="button" class="print-order btn-site" data-toggle="modal" data-target="#print-order-modal"><img src="img/note-blue.svg" hover-img="img/note-black.svg" class="img-fluid" alt="Order" />הדפס הזמנה לליקוט</button>
        <button  @click="cancelOrders()" type="button" class="cancel-order"  data-toggle="modal" data-target="#cancel-order-modal">
            <img src="img/delete.svg"  class="img-fluid" alt="Delete" />ביטול עסקה</button>
    </div>
    <printing-model :modaltext="printingModelText" :modalstatus="printingmodalstatus" :type="printingType" :orderids="this.orderSearch.orderCheckbox" @closeModal="closeModal"></printing-model>
</template>

<script>
import PrintingModel from '../../../components/printingModel'
import { mapState,mapActions } from 'vuex'
import { cancelorder } from '../../../services';
export default {
    components:{PrintingModel},
    computed: {
        ...mapState({ 
            orderSearch: state => state.ordersearch,
        }),
        ...mapActions('ordersearch', ['searchOrders','clearOrderCheckbox']),
    },
    data () {
        return {
            printingModelText: null,
            printingmodalstatus: false,
            printingType: null
        }
    },
    methods: {
        printStickers(type){
            this.printingType = type; 
            this.printingModelText = {'heading':'כמה עותקים להדפיס?','printing_text':'מדפיס הזמנה! המתן...'}
            this.printingmodalstatus = true;
        },
        closeModal(){
            this.printingmodalstatus = false;
        },
        cancelOrders(){
            let order_ids = this.orderSearch.orderCheckbox;
            let value = {'order_ids':order_ids};
            cancelorder.cancelOrder(value).then( response => {
                if(response.status == 'success'){
                    this.$store.dispatch('ordersearch/searchOrders');
                    this.$store.dispatch('ordersearch/clearOrderCheckbox');
                    this.$store.dispatch('alert/success','Order Cancel Successfully',{root:true});
                }else{
                    this.$store.dispatch('alert/error','failed to Cancel Order',{root:true});
                }
            });
        }
    }
};
</script>