<template>
    <div class="order-edit-details">
		<p class="order-date-time">
			<span class="d-block">הזמנות להכנה - 
				<template v-if="orderSearch.search && orderSearch.search.till_date && orderSearch.search.till_date != orderSearch.search.from_date">{{orderSearch.search.till_date}} - </template>
				<template v-if="orderSearch.search && orderSearch.search.from_date">{{orderSearch.search.from_date}}</template>
			</span>{{orderSearch.orders.length}} הזמנות להכנה  
		</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({ 
            orderSearch: state => state.ordersearch,
        }),
    }, 
};
</script>