<template>
	<div class="modal fade simple-popup product-quantity-modal print-order-modal" ref="print-order-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" 
    v-if="modalstatus" :class="modalstatus ? 'd-block show' : ''">
		<div class="modal-dialog">
            <div class="modal-content">
                 <div class="modal-header print-header">
                    <button type="button" class="close" data-dismiss="modal" @click="closePopup"  :class="processPrinting ? 'd-none' : ''">×</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="modal-title">{{modaltext.heading}}</h2>
                    <div class="animation-wrap" id="print-animation-img"  :class="processPrinting ? '' : 'd-none'">
                        <img src="img/animat-printer-color.gif" class="img-fluid" alt="Animate Gif"/>
                        <p>{{modaltext.printing_text}}</p>
                    </div>
                    <div class="add-to-cart"  id="print-order-quantity" :class="processPrinting ? 'd-none' : ''">
                        <div class="cart-wrap">
                            <a class="plus" href="#" title="הוסף" @click="plus"><i class="fa fa-plus"></i></a>
                            <p class="pro-unit">{{qty}}</p>
                            <a class="minus" href="#" title="הפחת" @click="minus"><i class="fa fa-minus"></i></a>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer"  :class="processPrinting ? 'd-none' : ''">
                    <button type="button" class="btn btn-success" id="print-order-confirm" @click="confirmprint()">לחץ להדפסה</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { orderprinting } from '../services';
export default {
    props:['modaltext','modalstatus','type','orderids'], 
    emits:['closeModal'],
    computed: {
        ...mapActions('ordersearch', ['clearOrderCheckbox']),
    },
     data () {
        return {
            processPrinting:false,
            qty:1,
        }
    },
    mounted() {
    },
    methods: {
        closePopup(){
            this.$emit('closeModal');
        },
        plus(){
            this.qty = this.qty + 1;
        },
        minus(){
            if(this.qty >1){
                this.qty = this.qty - 1;
            }
        },
        confirmprint(){
            this.processPrinting = true;
            let order_ids = this.orderids;
            let value = {'qty' : this.qty, 'order_id':order_ids};
            if(this.type == 'stickers'){
                orderprinting.printLabels(value).then( response => {
                    this.processPrinting = false;
                    this.$emit('closeModal');
                    this.$store.dispatch('ordersearch/clearOrderCheckbox');
                    if(response.type == 1){
                        window.open(response.url);
                    }
                });
            }else{
                orderprinting.printOrders(value).then( response => {
                    this.processPrinting = false;
                    this.$emit('closeModal')
                    this.$store.dispatch('ordersearch/clearOrderCheckbox');
                    window.open(response.url);
                });
            }
        }
    }
};
</script>
<style scoped>
.modal_show{
    display: block !important;
    display: block !important;
}
</style>