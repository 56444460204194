<template>
    <div class="user-info">
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#user-info">
            <img :src="userImage ? userImage : 'img/user.png'" alt="user" class="img-fluid" />
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
   computed: {
        ...mapState({ 
            userImage: state => state.user.userImage,
        }),
    }, 
};
</script>